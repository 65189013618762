import React from 'react';

const HomeAboutSection = (props) => {

    return (
        <section className="about-us-section light-bg">
        {/* <section className="about-us-section" style={{backgroundImage : 'url('+BgPattern+')'}} ref={sectionRef}> */}
            {/* <div className="container">
                <div className='about-detail'>
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="title-block">
                                <h2 className="h1 section-title normal-spacing">{props.mainTitle}</h2>
                                <p><Link to={props.linkURL}>{props.linkText}</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-12">
                            <p className="section-description">{props.mainDescription}</p>
                            <div className="count-items">
                                {props.list.map((object, i) => {
                                    return (
                                        <div className="item" key={shortid.generate()}>
                                            <div className="value">{parse(object.value)}</div>
                                            <div className="label"><p className="small">{parse(object.label)}</p></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="slider-block">
                <ImageSlider
                    imageSlider={props.imageSlider}
                />
            </div> */}
            {/* <div className='container'>
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-20">
                        <div className="awards-section">
                            <h2 className="h1 section-title normal-spacing">{props.awardMainTitle}</h2>
                            <p className="section-description">{props.awardMainDescription}</p>
                            <div className="award-items">
                                <div className="row">
                                    {props.awardsList.map((object, i) => {
                                        return (
                                            <div className="col-lg-6 col-sm-12" key={shortid.generate()}>
                                                <div className="item">
                                                    <div className="thumbnail">
                                                        <img
                                                            src={object.awardImage.sourceUrl}
                                                            width={object.awardImage.width}
                                                            alt={object.awardImage.altText}
                                                        />
                                                    </div>
                                                    <p className="small">{object.awardName}</p>
                                                    <p className="extra-small">{object.awardBy}</p>
                                                </div>
                                            </div>    
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div> */}
        </section>
    )
}

export default HomeAboutSection